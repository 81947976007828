import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker";
CheckWebpFeature();

export { wrapRootElement } from "./src/apollo/wrap-root-element";


export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
  
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/properties\/for-sale/)) {
      return false;
    }

    if (location.pathname.match(/properties\/for-rent/)) {
      return false;
    }

    if (location.pathname.match(/properties\/sold/)) {
      return false;
    }

    if (location.pathname.match(/properties\/let/)) {
      return false;
    }

    if (location.pathname.match(/properties\/commercial\/for-sale/)) {
      return false;
    }
    
    if (location.pathname.match(/properties\/commercial\/for-rent/)) {
      return false;
    }

    if (location.pathname.match(/properties-map\/for-sale/)) {
      return false;
    }

    if (location.pathname.match(/properties-map\/for-rent/)) {
      return false;
    }

    if (location.pathname.match(/properties-map\/sold/)) {
      return false;
    }

    if (location.pathname.match(/properties-map\/let/)) {
      return false;
    }

    if (location.pathname.match(/properties-map\/commercial\/for-sale/)) {
      return false;
    }
    
    if (location.pathname.match(/properties-map\/commercial\/for-rent/)) {
      return false;
    }

    if (location.pathname.match(/qatar-property-services\/buy-property\/view-for-our-latest-projects/)) {
      return false;
    }
  
    return true;
};